import { pick } from 'lodash';

import type { IUser } from '@wix/yoshi-flow-editor';
import {
  followMember,
  listMyMemberFollowing,
  unfollowMember,
} from '@wix/ambassador-members-v3-follow/http';
import { getMyMember } from '@wix/ambassador-members-v1-member/http';
import { Set as WixMembersFieldSet } from '@wix/ambassador-members-v1-member/types';
import { getSettings } from '@wix/ambassador-social-groups-v2-groups-app-settings/http';

import { mapMember } from 'api/members/selectors';

import {
  createAsyncThunk,
  getCurrentUserWithFallbackToInstance,
} from '../utils';
import type { IApplicationUser } from './types';

export const fetchSettings = createAsyncThunk(
  'application:fetchSettings',
  async (_: void, thunkAPI) => {
    const response = await thunkAPI.extra.httpClient.request(getSettings({}));

    return response.data.settings;
  },
);

export const follow = createAsyncThunk(
  'application:follow',
  async (memberId: string, thunkAPI) => {
    await thunkAPI.extra.httpClient.request(followMember({ memberId }));

    return memberId;
  },
);

export const unfollow = createAsyncThunk(
  'application:unfollow',
  async (memberId: string, thunkAPI) => {
    await thunkAPI.extra.httpClient.request(unfollowMember({ memberId }));

    return memberId;
  },
);

export const login = createAsyncThunk(
  'application:login',
  async (_, thunkAPI) => {
    const { controllerConfig } = thunkAPI.extra;

    try {
      await controllerConfig.wixCodeApi.user.promptLogin({ modal: true });

      return thunkAPI.dispatch(fetchCurrentUserProfile());
    } catch (err) {
      return thunkAPI.rejectWithValue(false);
    }
  },
  { hideErrorMessage: true },
);

export const fetchCurrentUserProfile = createAsyncThunk<
  Partial<IApplicationUser>,
  void,
  { pendingMeta: IUser }
>(
  'application:currentUserProfile',
  async function (_, thunkAPI) {
    const flowApi = thunkAPI.extra;

    const instance = flowApi.controllerConfig.wixCodeApi.site.getAppToken?.(
      flowApi.environment.appDefinitionId,
    );

    /**
     * When ooiInEditor CSM is enabled: https://bo.wix.com/client-spec-map-experiment-server/experiments/WixGroupsEditorOOI/148c2287-c669-d849-d153-463c7486a694
     * there is a known bug with userAPI: https://jira.wixpress.com/browse/USERS-7901
     *
     * So when our app is rendered inside the Editor with such CSM enabled user is marked as not logged in and his id is empty.
     *
     * As a workaround for now we can decode the instance to get the user siteMemberId from there (instance is a JWT token, where the second part after `.` is encoded with base64)
     *
     * TODO: migrate from user to members API
     * (as it is recommended in https://jira.wixpress.com/browse/USERS-7901?focusedCommentId=10645928&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-10645928)
     */
    const wixUser = getCurrentUserWithFallbackToInstance(
      flowApi.controllerConfig.wixCodeApi.user.currentUser,
      instance,
    );

    const user = pick(wixUser, 'loggedIn', 'role', 'instance');

    if (!wixUser.loggedIn) {
      return {
        following: [],
        siteMemberId: wixUser.id,
        ...user,
      };
    }

    const [response, following] = await Promise.all([
      flowApi.httpClient.request(
        getMyMember({
          fieldsets: [WixMembersFieldSet.FULL],
        }),
      ),
      flowApi.httpClient.request(listMyMemberFollowing({})).catch((error) => {
        flowApi.errorMonitor.captureException(error);

        return { data: { memberIds: [] } };
      }),
    ]);

    if (!response.data.member) {
      throw new Error('Current user profile not found');
    }

    return {
      ...user,
      ...mapMember(response.data.member),
      following: following.data.memberIds || [],
    } as IApplicationUser;
  },
  {
    getPendingMeta(base, thunkAPI) {
      const flowApi = thunkAPI.extra;

      const instance = flowApi.controllerConfig.wixCodeApi.site.getAppToken?.(
        flowApi.environment.appDefinitionId,
      );

      return getCurrentUserWithFallbackToInstance(
        flowApi.controllerConfig.wixCodeApi.user.currentUser,
        instance,
      );
    },
  },
);
